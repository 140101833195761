import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="inner">
                    <ul className="icons">
                        <li><a href="https://www.linkedin.com/in/mary-gocke-68148110/" className="icon fa-linkedin"><span className="label">linkedIn</span></a></li>
                        <li><a href="mailto:mary@mary-gocke.com" className="icon fa-envelope-o"><span className="label">Email</span></a></li>
                    </ul>
                    <ul className="copyright">
                        <li>&copy; <div className="year-block">{new Date().getFullYear()}</div> Mary Gocke</li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
