import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'


class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="#" className="image avatar"><img src={avatar} alt="" /></a>
                    <h1><div className="name">Mary Gocke, RDN CDN CHT</div><div class="subtitle">Integrative and Functional Medicine Nutritionist
                    <div style={{ paddingTop: 10 }}>Rapid Resolution Therapy Specialist</div>
                    </div></h1>
                    <hr/>
                    <ul className="nav-menu">
                        <li><a className='nav-item' href="#one">Introduction</a></li>
                        <li><a className='nav-item' href="#two">Accomplishments</a></li>
                    {/* <li><a className='nav-item' href="#three">Overview</a></li>  */}
                        <li><a className='nav-item' href="#four">Training</a></li>
                        <li><a className='nav-item' href="#five">Get In Touch</a></li>
                    </ul>
                
                </div>

                <Footer />
            </header>
        )
    }
}

export default Header
